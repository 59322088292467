<!-- eslint-disable vue/max-attributes-per-line -->
<template>
    <!-- 权属信息页面 -->
    <div class="subject">
        <!-- 步骤条组件 -->
        <main-steps :active="1"></main-steps>
        <div class="subject_main">
            <div class="subject_main_top">
                <span><img :src="titleIcon" alt="" /></span>
                <span class="subject_main_top_font">权属信息</span>
            </div>
            <div style="margin: 20px"></div>
            <el-form label-position="right" label-width="125px">
                <el-form-item label="权属身份类型">
                    <el-radio-group v-model="radio" size="small" fill="rgba(45,99,224,0.14)" text-color="#2D63E0">
                        <el-radio-button label="权利人" style="margin-left: -415px">
                            <img
                                :src="flag === true ? obligeeIcon : obligeeoIcon"
                                width="133%"
                                style="margin-left: -15px; height: 56px; margin-top: -9px; margin-bottom: -11px"
                            />
                        </el-radio-button>
                        <el-radio-button
                            label="代理人"
                            style="margin-left: 10px; border-left: 1px solid rgba(45, 99, 224, 0.14)"
                        >
                            <img
                                :src="blo === true ? agentIcon : agentoIcon"
                                width="133%"
                                style="margin-left: -17px; height: 56px; margin-top: -9px; margin-bottom: -11px"
                            />
                        </el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <obligee-ownership v-show="isshow"></obligee-ownership>
                <agent-ownership v-show="!isshow"></agent-ownership>
            </el-form>
        </div>
    </div>
</template>

<script>
import MainSteps from '@/views/MainSteps.vue';
import ObligeeOwnership from '@/components/ownership/ObligeeOwnership.vue';
import AgentOwnership from '@/components/ownership/AgentOwnership.vue';
import { ownership } from '@/api/getData.js';

export default {
    name: 'ownership',
    components: {
        'main-steps': MainSteps, // 步骤条组件
        'obligee-ownership': ObligeeOwnership, // 权利人组件
        'agent-ownership': AgentOwnership, // 代理人组件
    },
    data() {
        return {
            titleIcon: require('@/assets/img/title2.png'),
            obligeeIcon: require('@/assets/img/obligee2.png'),
            agentIcon: require('@/assets/img/agent2.png'),
            obligeeoIcon: require('@/assets/img/obligee.png'),
            agentoIcon: require('@/assets/img/agent.png'),
            radio: '权利人',
            isshow: true,
            flag: false,
            blo: true,
        };
    },
    mounted() {
        let own = sessionStorage.getItem('own');
        if (own) {
            own = JSON.parse(own);
        }
        if (own.type) {
            ownership({
                id: own.cp_id,
            }).then((res) => {
                if (res.code === 200) {
                    if (res.data.owner_type === 1) {
                        this.radio = '权利人';
                        sessionStorage.setItem('flag', this.flag);
                    } else {
                        this.radio = '代理人';
                        sessionStorage.setItem('flag', this.flag);
                    }
                }
            });
        } else {
            let flag = sessionStorage.getItem('flag');
            if (flag !== null) {
                if (flag === 'false') {
                    this.radio = '权利人';
                } else {
                    this.radio = '代理人';
                }
            } else {
                sessionStorage.setItem('flag', this.flag);
            }
        }
    },
    methods: {
        activated() {
            this.$nextTick(() => {
                this.$refs.ObligeeOwnership();
                this.$refs.AgentOwnership();
            });
        },
    },
    watch: {
        radio(newValue) {
            if (newValue === '权利人') {
                this.$store.commit('changePype', 1);
                this.isshow = true;
                this.flag = false;
                this.blo = true;
            } else {
                this.$store.commit('changePype', 2);
                this.isshow = false;
                this.blo = false;
                this.flag = true;
            }
            sessionStorage.setItem('flag', this.flag);
        },
    },
};
</script>

<style lang="less" scoped>
::v-deep .el-form-item__label {
    color: #383d47;
}
::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    box-shadow: none !important;
}
::v-deep .el-radio-button:focus:not(.is-focus):not(:active):not(.is-disabled) {
    box-shadow: none;
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #383d47;
}
::v-deep .el-radio-button {
    border: none !important;
    span {
        background: transparent !important;
    }
}
::v-deep .el-checkbox .el-checkbox__label {
    color: #383d47;
}
.subject {
    background: #ffffff;
    border-radius: 4px;
    width: 1200px;
    height: auto;
    margin: auto;
}
.subject_main {
    height: auto;
    width: 800px;
    min-height: 100vh;
    margin-left: 245px;
    ::v-deep .el-radio-group .el-radio-button img {
        border: 1px solid transparent;
    }
    ::v-deep .el-radio-group .el-radio-button:not(.is-active) img:hover {
        border-color: #2d63e0;
        border-radius: 4px;
    }
}
.subject_main_top {
    width: 95px;
    height: 20px;
    display: flex;
    justify-content: space-between;
    margin-left: 15px;
    margin-top: 20px;
}
.subject_main_top span img {
    width: 11px;
    height: 11px;
}
.subject_main_top_font {
    font-size: 20px;
    color: #1e2024;
    line-height: 20px;
    height: 20px;
    font-weight: bold;
}

.el-radio-button {
    width: 124px;
    height: 56px;
}

.el-form-item {
    font-size: 14px;
    color: #383d47;
    margin-bottom: 28px;
}
.tick {
    margin-left: -370px;
}

/deep/ .el-radio-button:first-child {
    .el-radio-button__inner {
        // border-radius: 4px;
        border: none;
    }
}
/deep/ .el-radio-button:last-child .el-radio-button__inner {
    // border-radius: 4px;
    border: none;
}
</style>