<!-- eslint-disable vue/max-attributes-per-line -->
<template>
    <!-- 步骤条组件 -->
    <div class="mainbgc">
        <div class="maintop">
            <el-steps :active="active" :space="180" align-center finish-status="success">
                <el-step title="帐号主体信息"></el-step>
                <el-step title="权属登记"></el-step>
                <el-step title="填写投诉链接"></el-step>
                <el-step title="身份真实性验证"></el-step>
                <el-step title="确认投诉内容"></el-step>
            </el-steps>
        </div>
    </div>
</template>

<script>
export default {
    name: 'mainsteps',
    data() {
        return {};
    },
    props: {
        active: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    mounted() {
        this.$store.commit('changeActive', this.active);
    },
};
</script>

<style scoped lang='less'>
::v-deep .el-steps .el-step__line {
    border-top: 2px dotted #9d9ea6;
    background: transparent;
    top: 12px;
}
::v-deep .el-steps .el-step__title {
    font-size: 16px;
    color: #9d9ea6;
}
::v-deep .el-steps {
    .el-step__head.is-wait {
        .el-step__icon {
            background: #9d9ea6;
            color: #9d9ea6;
            border: none;
            width: 12px;
            height: 12px;
        }
    }
    [class*=' el-icon-'],
    [class^='el-icon-'] {
        color: #2d63e0;
    }
    .el-step__head.is-success {
        color: transparent;
        border-color: transparent;
        .el-step__icon {
            background: #2d63e0;
            color: #2d63e0;
            border: none;
            width: 12px;
            height: 12px;
        }
    }
    .el-step__line-inner {
        width: 0 !important;
    }
    .is-process {
        color: #383d47;
        .el-step__icon {
            // z-index: 2;
            background: #2d63e0;
            color: #2d63e0;
            border: none;
            width: 13px;
            height: 13px;
        }
        &.el-step__head::before {
            position: absolute;
            content: '';
            display: block;
            width: 34px;
            height: 34px;
            left: 61px;
            top: -6px;
            border-radius: 50%;
            background: #fff;
            border: 2px solid #2d63e0;
            z-index: 1;
        }
    }
}
.mainbgc {
    background: #ffffff;
    border-radius: 4px;
    width: 1200px;
    margin: auto;
}
.mainbgc .maintop {
    width: 1152px;
    height: 131px;
    margin: auto;
    /* background-color: red; */
    border-bottom: 1px dotted #e6e6eb;
    overflow: hidden;
}
.el-steps {
    /* margin: 50px 192px 20px 208px; */
    width: 800px;
    margin: auto;
    height: 60px;
    margin-top: 50px;
}
</style>